import React, { useEffect, useState } from 'react';
import { USER_ROLES } from '../../../utils/constants';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../../utils/associateMembersConstants';
import './KnowledgeBaseContentLibrary.scss';
import Helpers from '../../../utils/helper';
import createNotification from '../../../utils/createNotification';
import KnowledgeBaseContentTile from './KnowledgeBaseContentTile';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import LibraryItemsWrapperComponent from '../LibraryItemsWrapperComponent/LibraryItemsWrapperComponent';
import { useStateSelector } from '../../../store/selectors';
import NoPageAccessAlert from '../../../components/NoPageAccessAlert/NoPageAccessAlert';

const KnowledgebaseContentSharePmgLibrary = (props) => {
    useEffect(() => {
        setIsLoading(true);
        setInitialData();
    }, []);
    const premiumnLibraryInfoText = `Premium content is made available to a PMG as that group demonstrates progress in its group learning. This progress is measured by considering the level of group interaction over time and of the group's utilization of available data analytics support services`;

    const axios = useStateSelector((state) => state.core.axios);
    const [premiumList, setPremiumList] = useState([]);
    const [shelfList, setShelfList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);

    useEffect(() => {
        setSelectedContent();
    }, [premiumList, shelfList]);

    const setInitialData = () => {
        if (isKbAvailableForUser()) {
            const libraryRequestResult = axios
                .get(`/api/KnowledgeBase/premiumContent/${props.groupId}`)
                .then((response) => {
                    if (
                        Helpers.isResponseSuccessful(
                            response,
                            'An error occured while fetching the Knowledge Library.'
                        )
                    ) {
                        let list = response.data.data;
                        list = list.map((item) => {
                            item.isSelected = premiumList?.find(
                                (f) => f.id === item.id
                            )?.isSelected;
                            return item;
                        });
                        setPremiumList(list);
                    }
                })
                .catch((error) => {
                    createNotification(error.message, 'error');
                });

            const shelfResultRequest = axios
                .get(`/api/KnowledgeBase/group/${props.groupId}/sharedContent`)
                .then((response) => {
                    if (
                        Helpers.isResponseSuccessful(
                            response,
                            'An error occured while fetching My Shelf.'
                        )
                    ) {
                        let responseData = response.data.data;
                        responseData = responseData.map((item) => {
                            item.isSelected = premiumList.find(
                                (f) => f.id === item.id
                            )?.isSelected;

                            return item;
                        });
                        setShelfList(response.data.data);
                    }
                })
                .catch((error) => {
                    createNotification(error.message, 'error');
                });

            Promise.allSettled([libraryRequestResult, shelfResultRequest]).then(
                () => setIsLoading(false)
            );
        }
    };

    const selectItem = (contentId) => {
        const library = updateStatusContentList(premiumList, contentId, true);
        setPremiumList(library);
        const shelf = updateStatusContentList(shelfList, contentId, true);
        setShelfList(shelf);
    };

    const deselectItem = (contentId) => {
        const library = updateStatusContentList(premiumList, contentId, false);
        setPremiumList(library);
        const shelf = updateStatusContentList(shelfList, contentId, false);
        setShelfList(shelf);
    };

    const setSelectedContent = () => {
        let selectedContent = combineArrays(premiumList, shelfList)
            ?.filter((f) => f.isSelected)
            ?.map((item) => {
                return { id: item?.id, title: item?.title };
            });
        props.onSelect(selectedContent);
    };

    function combineArrays(arr1, arr2) {
        const combinedArray = [...arr1, ...arr2];
        const uniqueArray = Array.from(
            new Set(combinedArray.map((item) => item.id))
        ).map((id) => combinedArray.find((item) => item.id === id));
        return uniqueArray;
    }

    const updateStatusContentList = (list, contentId, selectedStatus) => {
        const itemIndex = list.findIndex((f) => f.id === contentId);
        const updatedList = [...list];
        const item = { ...updatedList[itemIndex] };
        item.isSelected = selectedStatus;
        updatedList[itemIndex] = item;
        return updatedList;
    };

    const isKbAvailableForUser = () => {
        let loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        if (loggedInUserSummary) {
            const summary = JSON.parse(loggedInUserSummary);
            const isOwner = summary.roleId === USER_ROLES.PRACTICE_OWNER;
            const isAssociate =
                summary.roleId === USER_ROLES.PRACTICE_OWNER ||
                summary.hasReferralRequest ||
                summary.roleId === USER_ROLES.USER ||
                (summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
                    summary.associateMember &&
                    summary.associateMember.some((x) =>
                        x.practices.some((p) =>
                            p.acceptedAccessRights.includes(
                                ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                            )
                        )
                    ));
            if (
                (isOwner &&
                    summary.practices?.filter((x) => x.status == 1).length >
                        0) ||
                isAssociate
            ) {
                setIsError(false);
                return true;
            }
        }

        return false;
    };

    return (
        <>
            {isError ? (
                <NoPageAccessAlert />
            ) : (
                <div className="associate-members-table kb-content-library">
                    <div className="responsiveClass">
                        <div className="body_white_box knowledgeLibraryMainSSection">
                            <div className="overFlowClass">
                                <div className="body_header_title">
                                    <div className="browseTitle">
                                        <label>
                                            {' '}
                                            Premium Content{' '}
                                            <InfoTooltip
                                                text={premiumnLibraryInfoText}
                                                idText="pmg-kl-premium"
                                                place="right"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {isLoading ? (
                                Helpers.renderTableLoader()
                            ) : (
                                <div className="browse_the_library_main">
                                    {premiumList && premiumList.length > 0 ? (
                                        <div className="browse_the_library_wrapper">
                                            <LibraryItemsWrapperComponent
                                                isSelectable={true}
                                                contentList={premiumList}
                                                setInitialData={setInitialData}
                                                selectItem={(contentId) =>
                                                    selectItem(contentId)
                                                }
                                                deselectItem={(contentId) =>
                                                    deselectItem(contentId)
                                                }></LibraryItemsWrapperComponent>
                                        </div>
                                    ) : (
                                        <div className="no-content-label">
                                            No Content available
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="associate-members-table kb-content-library">
                        <div className="body_white_box myShelfSection">
                            <div className="body_header_title">
                                <div className="browseTitle">
                                    <div className="shelf-header">
                                        <div className="shelf-title">
                                            <label>Group Shelf</label>
                                            <InfoTooltip
                                                text="The group shelf is the union of all of the group's member's individual shared shelves from the Knowledge Library"
                                                idText="kb-myshelf"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoading ? (
                                Helpers.renderTableLoader()
                            ) : shelfList && shelfList.length ? (
                                <div className="browse_the_library_main">
                                    <div className="browse_the_library_wrapper">
                                        <div className="browse_the_library_item">
                                            {shelfList.map((cont, _index) => (
                                                <KnowledgeBaseContentTile
                                                    isSelectable={true}
                                                    updateTables={() =>
                                                        setInitialData()
                                                    }
                                                    data={cont}
                                                    selectItem={(contentId) =>
                                                        selectItem(contentId)
                                                    }
                                                    deselectItem={(contentId) =>
                                                        deselectItem(contentId)
                                                    }></KnowledgeBaseContentTile>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="browse_the_library_main">
                                    <div className="no-content-label">
                                        No Content available
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default KnowledgebaseContentSharePmgLibrary;
