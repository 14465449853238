export interface PracticeOption {
    option: string;
    free: string;
    paid: string;
}

interface BasePracticeOwnerProps {
    annualSubscription: string;
    freePmgMembershipFee: string;
    paidPmgMembershipFee: string;
}

export interface IndependentPracticeOwnerProps extends BasePracticeOwnerProps {};

export interface CorporateGroupLeaderProps extends BasePracticeOwnerProps {
    monthlySubscription: string;
};

export const independentPracticeOwnerText = "Complete access to the EcoSystem requires both a paid membership and " +
    "confirmation of your identity as an independent practice owner. Upgrading requires that you enter your credit card " +
    "information, with which we will charge the annual membership fee only once we have confirmed your identity as an " +
    "independent practice owner.";

export const corporatePracticeLeaderText = "Complete access to the EcoSystem requires both a paid membership and " +
    "confirmation of your identity as a corporate-owned practice leader. Upgrading requires that you enter your credit " +
    "card information, with which we will charge the annual membership fee only once we have confirmed your identity as " +
    "a corporate-owned practice leader.";

export const independentPracticeOwnerOptions = (props: IndependentPracticeOwnerProps): PracticeOption[] => [
    {
        option: 'Cost',
        free: 'Free',
        paid: `$${props.annualSubscription}/Year`
    },
    {
        option: 'Announcements',
        free: 'Included',
        paid: 'Included'
    },
    {
        option: 'Independent Practice Owners Community (IPOC)',
        free: 'NA',
        paid: 'Included'
    },
    {
        option: 'Knowledge Library',
        free: 'Basic Content Included and/or Available',
        paid: 'Basic and Enhanced Content Included and/or Available'
    },
    {
        option: 'Affiliated Members',
        free: 'NA',
        paid: 'Available'
    },
    {
        option: 'Valuation Reports',
        free: 'Monthly F&E Included, CAPVAR available',
        paid: 'Unlimmited F&E Included, APVAR/CAPVAR available'
    },
    {
        option: 'Practice Management Groups (PMGs)',
        free: `Available, $${props.freePmgMembershipFee}/Year`,
        paid: `Available, $${props.paidPmgMembershipFee}/Year`
    },
    {
        option: 'Current Brokerage Services',
        free: 'Available',
        paid: 'Available'
    },
    {
        option: 'Direct Messaging',
        free: 'NA',
        paid: 'Included'
    },
    {
        option: 'Notifications',
        free: 'NA',
        paid: 'Included'
    },
    {
        option: 'Gift Card Purchases',
        free: 'Available',
        paid: 'Available'
    },
    {
        option: 'Frequency Discounts',
        free: 'Applicable',
        paid: 'Applicable'
    },
    {
        option: 'VV Connect Marketplace (Coming 2H2025)',
        free: 'NA',
        paid: 'NA'
    },
];

export const corporatePracticeLeaderOptions = (props: CorporateGroupLeaderProps): PracticeOption[] => [
    {
        option: 'Cost',
        free: 'Free',
        paid: `$${props.monthlySubscription}/Month OR $${props.annualSubscription}/Year`
    },
    {
        option: 'Announcements',
        free: 'Included',
        paid: 'Included'
    },
    {
        option: 'Practice Leader Community (PLC)',
        free: 'NA',
        paid: 'Included'
    },
    {
        option: 'Knowledge Library',
        free: 'Basic Content Included and/or Available',
        paid: 'Basic and Enhanced Content Included and/or Available'
    },
    {
        option: 'Affiliated Members',
        free: 'NA',
        paid: 'Available'
    },
    {
        option: 'Valuation Reports',
        free: 'Monthly F&E Included, CAPVAR available',
        paid: 'Unlimmited F&E Included, APVAR/CAPVAR available'
    },
    {
        option: 'Practice Management Groups (PMGs)',
        free: `Available, $${props.freePmgMembershipFee}/Year`,
        paid: `Available, $${props.paidPmgMembershipFee}/Year`
    },
    {
        option: 'Direct Messaging',
        free: 'NA',
        paid: 'Included'
    },
    {
        option: 'Notifications',
        free: 'NA',
        paid: 'Included'
    },
    {
        option: 'Gift Card Purchases',
        free: 'Available',
        paid: 'Available'
    },
    {
        option: 'Frequency Discounts',
        free: 'Applicable',
        paid: 'Applicable'
    }
];
