import { createSlice } from '@reduxjs/toolkit';
import { LoadCurrentUserIfNotSet } from './load-current-user-if-not-set.thunk';
import { CurrentUserState } from './current-user.state';
import { LoadLimits } from './load-limits.thunk';
import { encryptData } from '../../utils/encryptionService';
import { LoadLimitsFromStorage } from './load-limits-from-storage.thunk';

const initialState: CurrentUserState = {
    user: {
        id: 0,
        roleId: 0,
    },
    limits: {
        doesSupportSubscriptions: false,
        hasPaidSubscription: false,
        isUpgradeAvailable: false,
        currentSubscriptionLimitsState: {
            numberOfAvailableFaEReports: 0,
            numberOfFAReportsPerMonth: 0,
            doesPocAvailable: false,
            doesPurchasingEfficienciesAvailable: false,
            doesStandardizationAvailable: false,
            doesBrokerageAvailable: false,
            doesPMGAvailable: false,
            doesNotificationsAvailable: false,
        },
        paidSubscriptionLimits: {
            numberOfAvailableFaEReports: 0,
            numberOfFAReportsPerMonth: 0,
            doesPocAvailable: false,
            doesPurchasingEfficienciesAvailable: false,
            doesStandardizationAvailable: false,
            doesBrokerageAvailable: false,
            doesPMGAvailable: false,
            doesNotificationsAvailable: false,
        },
    },
    isLoading: false,
    status: 'succeeded',
    error: '',
};

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(LoadCurrentUserIfNotSet.pending, (state) =>
                setPendingState(state)
            )
            .addCase(LoadCurrentUserIfNotSet.fulfilled, (state, action) => {
                if (action.payload?.status === 200) {
                    const currentUser = action.payload.data;
                    state.user = currentUser;
                    setSucceededState(state);
                }
            })
            .addCase(LoadCurrentUserIfNotSet.rejected, (state) =>
                setRejectedState(state)
            )
            .addCase(LoadLimits.pending, (state) => setPendingState(state))
            .addCase(LoadLimits.fulfilled, (state, action) => {
                if (action.payload?.status === 200) {
                    const encryptedLimits = encryptData(action.payload.data);
                    localStorage.setItem('encryptedLimits', encryptedLimits);
                    state.limits = action.payload.data;
                    setSucceededState(state);
                }
            })
            .addCase(LoadLimits.rejected, (state) => setRejectedState(state))
            .addCase(LoadLimitsFromStorage.fulfilled, (state, action) => {
                state.limits = action.payload ?? state.limits;
            });
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};
export const {} = currentUserSlice.actions;
export default currentUserSlice.reducer;
