import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import {
    NUMBER_OF_OWNERS_DROPDOWN_ITEMS,
    PRACTICE_FOCUS_DROPDOWN_ITEMS,
    OWNERS_PRACTICE,
} from './../../utils/practiceProfileConstants';
import { Form, Field, Input } from '@availity/form';
import '@availity/yup';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../utils/helper';
import InfoTooltip from '../../components/info-tooltip/info-tooltip';
import './PracticeProfileFields.scss';
import { useStateSelector } from '../../store/selectors';

const PracticeProfileFields = (props) => {
    const [isShortView, setIsShortView] = useState(true);
    const [fteDvmsRange, setFteDvmsRange] = useState([]);
    const fteDvmTooltipText =
        'FTE DVM = 36 clinical hours per week. For example, a DVM working 18 hours per week would be 0.5 FTE DVMS.';

    const axios = useStateSelector((state) => state.core.axios);

    const zipCodeCache = useRef({}).current;
    let formRef = {};

    const phoneStyles = {
        invalidStyle: {
            border: '1px solid red',
            borderRadius: '5px',
        },
        validStyle: {
            border: '',
        },
    };
    const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
    const [phoneAlreadyUsed, setPhoneAlreadyUsed] = useState(false);
    const [initialPhone, setInitialPhone] = useState(
        (props.practice.practicePhone ?? props.practice.phone)?.replace(
            /[-() ]/g,
            ''
        )
    );

    useEffect(() => {
        setIsShortView(props.isShortView);
        setFteDvmsRange(Helpers.getFteDvmsRange());
    }, []);

    useEffect(() => {
        if (!props.isEditMode) {
            if (formRef.resetForm) {
                formRef.resetForm();
            }
        }
    }, [props.isEditMode]);

    useEffect(() => {
        if (!props.isShortView) {
            props.onSaveCallback(() => formRef.handleSubmit);
        }
    }, [formRef]);

    const nameField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">Practice Name</Label>
                    <h6>{props.practice.name}</h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Field type="text" label="Practice Name" name="name" />
                </FormGroup>
            )}
        </>
    );

    const addressField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">Practice Street Address</Label>
                    <h6>{props.practice.address}</h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Field
                        type="text"
                        label="Street Address"
                        name="address"
                        id="txtAddress"
                    />
                </FormGroup>
            )}
        </>
    );

    const cityField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">City</Label>
                    <h6>{props.practice.city}</h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Field type="text" label="City" name="city" id="txtCity" />
                </FormGroup>
            )}
        </>
    );

    const stateField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">State</Label>
                    <h6>{props.practice.state}</h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Field
                        type="text"
                        label="State"
                        name="state"
                        id="txtState"
                    />
                </FormGroup>
            )}
        </>
    );

    const zipCodeField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">Practice ZIP Code</Label>
                    <h6>{props.practice.zipCode}</h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Field
                        style={{ width: '10ch' }}
                        type="string"
                        label="Practice ZIP Code"
                        name="zipCode"
                        maxLength={5}
                        onKeyDown={(event) => {
                            var key = event.keyCode || event.charCode;

                            var digits = key > 47 && key < 58;
                            var validControls =
                                key == 8 ||
                                key == 9 ||
                                key == 46 ||
                                (event.ctrlKey &&
                                    (key == 67 ||
                                        key == 86 ||
                                        key == 88 ||
                                        key == 89 ||
                                        key == 90)) ||
                                key == 37 ||
                                key == 39;

                            if (!(digits || validControls)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </FormGroup>
            )}
        </>
    );

    const phoneField = (
        <FormGroup>
            <Label for="name">Practice Phone</Label>
            {!props.isEditMode ? (
                <h6>
                    {Helpers.getFormattedPhoneNumberWithoutPrefix(
                        props.practice.phone
                    )}
                </h6>
            ) : (
                <FormGroup>
                    <div className="flex-container">
                        <div className="input-group-text prepend-addon">
                            <FontAwesomeIcon icon={faPhone} />
                        </div>
                        <InputMask
                            type="tel"
                            className="form-control postpend-addon"
                            style={
                                phoneNumberInvalid || phoneAlreadyUsed
                                    ? phoneStyles.invalidStyle
                                    : phoneStyles.validStyle
                            }
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                            id="practicePhone"
                            maskChar={null}
                            defaultValue={props.practice.phone}
                            onBlur={(event) => {
                                const isValid =
                                    event.target.value &&
                                    event.target.value.replace(/[-() ]/g, '')
                                        .length === 10;
                                setPhoneNumberInvalid(!isValid);
                            }}
                            onChange={(event) => {
                                formRef.setFieldValue(
                                    'practicePhone',
                                    event.target.value
                                );
                                setPhoneNumberInvalid(
                                    event.target.value &&
                                        event.target.value.replace(
                                            /[-() ]/g,
                                            ''
                                        ).length === 10
                                        ? false
                                        : true
                                );
                            }}
                        />
                    </div>
                </FormGroup>
            )}
        </FormGroup>
    );

    const websiteField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">Website</Label>
                    <h6>{props.practice.website}</h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Field
                        label="Website"
                        type="text"
                        id="txtWebsite"
                        name="website"
                    />
                </FormGroup>
            )}
        </>
    );

    const numberOfOwnersField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">Number of Owners:</Label>
                    <h6>
                        {
                            NUMBER_OF_OWNERS_DROPDOWN_ITEMS.find(
                                (item) => item.id === props.practice.noofOwners
                            )?.name
                        }
                    </h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Label for="name">Number of Owners:</Label>
                    <Input type="select" name="noofOwners">
                        {NUMBER_OF_OWNERS_DROPDOWN_ITEMS.map((item) => (
                            <option
                                value={item.id}
                                key={'numberOfOwners' + item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            )}
        </>
    );

    const fteDvmsTooltip = (
        <InfoTooltip
            idText="practice-info-fte-dvms"
            text={fteDvmTooltipText}></InfoTooltip>
    );

    const numberOfFteDvmsField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">
                        {'Number of FTE DVMs: '}
                        {fteDvmsTooltip}
                    </Label>
                    <h6>
                        {fteDvmsRange.find(
                            (item) => item.id === props.practice.noOfFteDvms
                        )?.name ?? 'NA'}
                    </h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Label for="name">
                        {'Number of FTE DVMs:'}
                        {fteDvmsTooltip}
                    </Label>
                    <Input type="select" name="noOfFteDvms">
                        {fteDvmsRange.map((item) => (
                            <option
                                disabled={item.id === ''}
                                value={item.id}
                                key={'numberOfFteDvms' + item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            )}
        </>
    );

    const focusField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">Practice Focus:</Label>
                    <h6>
                        {
                            PRACTICE_FOCUS_DROPDOWN_ITEMS.find(
                                (item) =>
                                    item.id === props.practice.practiseTypeID
                            )?.name
                        }
                    </h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Label for="name">Practice Focus:</Label>
                    <Input type="select" name="practiseTypeID">
                        {PRACTICE_FOCUS_DROPDOWN_ITEMS.map((item) => (
                            <option
                                value={item.id}
                                key={'practiseTypeID' + item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            )}
        </>
    );

    const ownersPracticeField = (
        <>
            {!props.isEditMode ? (
                <FormGroup>
                    <Label for="name">
                        Owner Actively Practicing at Practice?
                    </Label>
                    <h6>
                        {
                            OWNERS_PRACTICE.find(
                                (item) =>
                                    item.key === props.practice.ownersPractice
                            )?.name
                        }
                    </h6>
                </FormGroup>
            ) : (
                <FormGroup>
                    <Label for="name">
                        Owner Actively Practicing at Practice?
                    </Label>
                    <Input type="select" name="ownersPractice">
                        {OWNERS_PRACTICE.map((item) => (
                            <option
                                value={item.key}
                                key={'ownersPractice_' + item.key}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            )}
        </>
    );

    const fullview = (
        <Form
            id="form"
            innerRef={(c) => (formRef = c)}
            onSubmit={(fields) => {
                const fields_copy = { ...fields };
                fields_copy.practicePhone = Helpers.trimPhoneNumberFromMask(
                    fields_copy.practicePhone
                );
                props.savePractice(fields_copy);
            }}
            initialValues={{
                name: props?.initialPractice?.name,
                address: props?.initialPractice?.address,
                city: props?.initialPractice?.city,
                state: props?.initialPractice?.state,
                zipCode: props?.initialPractice?.zipCode,
                website: props?.initialPractice?.website,
                practicePhone:
                    props.practice.practicePhone ?? props.practice.phone,
                noofOwners: props?.initialPractice?.noofOwners,
                noOfFteDvms: !props?.initialPractice?.noOfFteDvms
                    ? ''
                    : props.initialPractice.noOfFteDvms,
                practiseTypeID: props?.initialPractice?.practiseTypeID,
                ownersPractice: props?.initialPractice?.ownersPractice,
            }}
            validationSchema={yup.object().shape({
                name: yup.string().trim().required(),
                address: yup.string().trim().required(),
                city: yup.string().trim().required(),
                state: yup.string().trim().required(),
                website: yup.string().trim().required(),
                noofOwners: yup.string().required(),
                noOfFteDvms: yup.string().required(),
                practiseTypeID: yup.string().required(true),
                ownersPractice: yup.string().required(true),
                zipCode: yup
                    .string()
                    .length(5)
                    .test('zip', 'Zip invalid', async (value) => {
                        if (!value || value.toString().length !== 5) {
                            return false;
                        }

                        if (value.toString() === '00000') {
                            return false;
                        }

                        if (
                            typeof zipCodeCache[value] === 'boolean' &&
                            zipCodeCache[value]
                        ) {
                            return true;
                        }

                        let response = await axios
                            .get('api/location/zip/' + value)
                            .catch(() => false);

                        if (response && response.status) {
                            zipCodeCache[value] = response.status === 200;
                            return response.status === 200;
                        } else if (
                            response &&
                            response.response &&
                            response.response.status
                        ) {
                            zipCodeCache[value] =
                                response.response.status === 200;
                            return response.response.status === 200;
                        }
                    }),
                practicePhone: yup
                    .string()
                    .test(
                        'phoneUsed',
                        'Phone is already used',
                        async (value) => {
                            const cleanedValue = value.replace(/[-() ]/g, '');

                            if (cleanedValue.length !== 10) {
                                setPhoneNumberInvalid(true);
                                return false;
                            }
                            if (cleanedValue == initialPhone) {
                                return true;
                            }

                            const response = await axios.get(
                                '/api/Practice/IsPhoneNumberUsed',
                                {
                                    params: {
                                        phone: Helpers.trimPhoneNumberFromMask(
                                            value
                                        ),
                                    },
                                }
                            );
                            const result =
                                response.status === 200 && !response.data;
                            setPhoneAlreadyUsed(!result);
                            return result;
                        }
                    ),
            })}>
            <Row>
                <Col md={12}>{nameField}</Col>
            </Row>
            <Row>
                <Col md={12}>{addressField}</Col>
            </Row>
            <Row>
                <Col md={4}>{cityField}</Col>
                <Col md={4}>{stateField}</Col>
                <Col md={4}>{zipCodeField}</Col>
            </Row>
            <Row>
                <Col md={6}>{phoneField}</Col>
                <Col md={6}>{websiteField}</Col>
            </Row>
            <Row>
                <Col md={6}>{numberOfOwnersField}</Col>
                <Col md={6}>{numberOfFteDvmsField}</Col>
            </Row>
            <Row style={{ alignItems: 'baseline' }}>
                <Col md={6}>{focusField}</Col>
                <Col md={6}>{ownersPracticeField}</Col>
            </Row>
        </Form>
    );

    const shortView = (
        <>
            <Row>
                <Col md={4}>{addressField}</Col>
                <Col md={4}>{phoneField}</Col>
                <Col md={4}>{websiteField}</Col>
            </Row>
            <Row>
                <Col md={4}>{cityField}</Col>
                <Col md={4}>{stateField}</Col>
                <Col md={4}>{zipCodeField}</Col>
            </Row>
            <Row>
                <Col md={2}>{numberOfOwnersField}</Col>
                <Col md={2}>{numberOfFteDvmsField}</Col>
                <Col md={4}>{focusField}</Col>
                <Col md={4}>{ownersPracticeField}</Col>
            </Row>
        </>
    );

    return (
        <div className="practice-profile-fields">
            {isShortView ? shortView : fullview}
        </div>
    );
};
export default PracticeProfileFields;
