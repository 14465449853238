import React from 'react';
import './NoPageAccessAlert.scss';

interface NoPageAccessAlertProps {
    text?: string;
}

const NoPageAccessAlert = (props: NoPageAccessAlertProps) => {
    const defaultText = 'You do not have access to view this page';
    return (
        <h2 className="no-page-access-alert">{props.text ?? defaultText}</h2>
    );
};

export default NoPageAccessAlert;
