import React, { useEffect, useRef, useState } from 'react';
import { Message } from '../models/message';
import Helpers from '../../utils/helper';
import './messages.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import { Attachment } from '../models/attachment';
import download from '../../assets/images/download.png';
import { Member } from '../models/member';
import { OnlineState } from '../models/online-state';
import { faArrowDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { ComparationType } from '../slices/messages/load-discussion-messages-by-likes.thunk';
import { Badge } from 'reactstrap';
import KnowledgeBaseContentTile from '../../containers/KnowledgeBase/KnowledgeBaseContentLibrary/KnowledgeBaseContentTile';
import { MessageReference } from '../models/message-reference';
import {
    SupportContextEnum,
    SupportContextItemList,
} from '../../support-portal/models/SupportContext';
import editAction from '../../assets/knowledgecontent/editAction.png';
import Autolinker from 'autolinker';
import GiftCard from '../../components/GiftCard/GiftCard';

interface MessagesProps {
    supportContext?: SupportContextEnum;
    authorizedMemberId: number;
    members: Array<Member>;
    messages: Array<Message>;
    loadMessagesPage(isAscending: boolean): void;
    loadMessagesPageByLikes(
        isAscending: boolean,
        numberOfLikes: number,
        comparationType: ComparationType
    ): void;
    isMessageLoading: boolean;
    isPageLoading: boolean;
    isAdmin: boolean;
    showNewMessages(): any;
    isNewMessageReceived: boolean;
    hasFirstPage: boolean;
    shouldScrollBottom: boolean;
    setShouldScrollBottom(e: boolean): void;
    setIsNewMessageReceived(e: boolean): void;
    selectedDiscussionId: number;
    numberOfLikes: number | null;
    comparationType: ComparationType;
    likeMessage: (messageid: number) => boolean;
    resetFilter: () => void;
    openAttachment: (attachmentId: number, messageId: number) => void;
    updateContent(
        discussionId: number,
        messageId: number,
        contentId: number,
        isAddedToShelf: boolean
    ): void;
    isReadonlyMode: boolean;
    onReadNewMessages: () => void;
    messageReference?: MessageReference;
    resetMessageReference?: () => void;
    showProfile: (id: number) => void;
    isDisableKbContent: boolean;
    setMessageToEdit?: (message: Message) => void;
    editingMessageId?: number;
}

const Messages = (props: MessagesProps) => {
    const autoScroller = useRef(null);
    const areNearlySame = (x: number, y: number) => Math.abs(x - y) <= 1;
    const [firstElementId, setFirstElementId] = useState(0);
    const [lastElementId, setLastElementId] = useState(0);
    const firstElementRef = useRef(null);
    const lastElementRef = useRef(null);
    const referenceMessageRef = useRef(null);
    const [action, setAction] = useState(null);
    const [isMessagesInitialized, setIsMessagesInitialized] = useState(false);
    const [isShowNewMessageVisible, setIsShowNewMessageVisible] =
        useState(false);
    const [highlightedMessageId, setHighlightedMessageId] = useState(0);
    const [highlightedMessageTimeout, setHighlightedMessageTimeout] =
        useState<NodeJS.Timeout>();

    const shouldScroll = () => {
        const scrollArea = autoScroller?.current;
        return (
            scrollArea.scrollHeight -
                scrollArea.scrollTop -
                scrollArea.clientHeight <
            300
        );
    };

    const [isNewDiscussion, setIsNewDiscussion] = useState(false);

    useEffect(() => {
        if (!props.messageReference) {
            if (!props.messages) {
                setIsNewDiscussion(true);
            } else {
                const scrollArea = autoScroller?.current;
                scrollArea.scrollTop = scrollArea.scrollHeight;
            }
            props.onReadNewMessages();
        }
    }, [props.selectedDiscussionId]);

    useEffect(() => {
        if (props.numberOfLikes !== null) {
            setAction('bottom');
        }
    }, [props.numberOfLikes, props.comparationType]);

    useEffect(() => {
        const scrollArea = autoScroller?.current;
        if (scrollArea && !isMessagesInitialized && !props.isPageLoading) {
            scrollArea.scrollTop = scrollArea.scrollHeight;
            setIsMessagesInitialized(true);
        }
    }, [props.isPageLoading]);

    useEffect(() => {
        if (props.messageReference) {
            setHighlightedMessageId(props.messageReference.messageId);
            clearTimeout(highlightedMessageTimeout);
            const timeout = setTimeout(() => setHighlightedMessageId(0), 10000);
            setHighlightedMessageTimeout(timeout);
        }
    }, [props.messageReference]);

    const loadMessagePage = (e: any) => {
        if (!props.isPageLoading) {
            const bottomScrollPosition =
                e.target.scrollHeight - e.target.clientHeight;

            if (areNearlySame(e.target.scrollTop, 0)) {
                setAction('top');
                if (props.numberOfLikes !== null) {
                    props.loadMessagesPageByLikes(
                        false,
                        props.numberOfLikes,
                        props.comparationType
                    );
                } else {
                    props.loadMessagesPage(false);
                }
            } else if (
                areNearlySame(e.target.scrollTop, bottomScrollPosition)
            ) {
                setAction('bottom');
                if (props.numberOfLikes !== null) {
                    props.loadMessagesPageByLikes(
                        true,
                        props.numberOfLikes,
                        props.comparationType
                    );
                } else {
                    props.loadMessagesPage(true);
                }
            }
        }
    };

    useEffect(() => {
        if (props.messages && props.messages.length > 0) {
            if (props.messageReference) {
                scrollToRef(referenceMessageRef, 'center');
                setTimeout(() => props.resetMessageReference(), 100);
                setAction(null);
                resetRefs(props.messages);
                return;
            }
            const scrollArea = autoScroller?.current;

            if (isNewDiscussion) {
                scrollArea.scrollTop = scrollArea.scrollHeight;
                setIsNewDiscussion(false);
            }

            if (
                scrollArea &&
                !props.isMessageLoading &&
                shouldScroll() &&
                !props.messageReference &&
                props.numberOfLikes === null
            ) {
                scrollArea.scroll({
                    top: scrollArea.scrollHeight,
                    behavior: 'smooth',
                });
            } else {
                if (action === 'bottom' && shouldScroll()) {
                    scrollToRef(lastElementRef, 'end');
                } else if (action === 'top') {
                    scrollToRef(firstElementRef, 'start');
                } else if (props.shouldScrollBottom) {
                    scrollArea.scrollTop = scrollArea.scrollHeight;
                }
            }
            setAction(null);
            resetRefs(props.messages);
        }
    }, [props.messages, props.isMessageLoading]);

    useEffect(() => {
        if (props.hasFirstPage) {
            setIsShowNewMessageVisible(
                props.isNewMessageReceived && !shouldScroll()
            );
        } else {
            setIsShowNewMessageVisible(props.isNewMessageReceived);
        }
        if (
            props.hasFirstPage &&
            shouldScroll() &&
            (props.isNewMessageReceived ||
                props.isNewMessageReceived == undefined)
        ) {
            props.onReadNewMessages();
        }
    }, [props.isNewMessageReceived, props.hasFirstPage, props.messages]);

    const resetRefs = (messages: Array<Message>) => {
        if (messages) {
            setFirstElementId(messages[0]?.id);
            setLastElementId(messages[messages.length - 1]?.id);
        }
    };

    const scrollToRef = (ref: any, position: string) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ block: position });
        }
    };

    const showNewMessages = () => {
        props.resetFilter();
        if (props.hasFirstPage) {
            const scrollArea = autoScroller?.current;
            if (scrollArea) {
                scrollArea.scrollTop = scrollArea.scrollHeight;
                props.onReadNewMessages();
            }
        } else {
            props.showNewMessages().then((response: any) => {
                resetRefs(response?.payload?.data);
                scrollToRef(lastElementRef, 'end');
            });
        }
    };

    const getAttachment = (
        attachment: Attachment,
        messageId: number,
        isOnlyFile: boolean,
        isSender: boolean
    ) => {
        const isSenderClass = isSender
            ? 'attached-file-owner'
            : 'attached-file';
        const isOnlyFileClass = isOnlyFile ? '' : 'attached-file-wrapper';
        return (
            <div
                key={attachment.id}
                className={`${isSenderClass} ${isOnlyFileClass}`}
                onClick={() => props.openAttachment(attachment.id, messageId)}>
                <div className="file-icon-container">
                    <FontAwesomeIcon
                        icon={attachment.type.icon}
                        className="file-icon"
                    />
                </div>
                <div className="file-name-container">
                    <div className="file-name" title={attachment.name}>
                        {attachment.name}
                    </div>
                    <span className="file-size">
                        {Helpers.formatBytes(attachment.size)}
                    </span>
                </div>
                <div>
                    <img src={download} className="download-file" />
                </div>
            </div>
        );
    };

    const calculateTextWidth = (text: string, font: string) => {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        context.font = font;
        let metrics = context.measureText(text);
        return metrics.width;
    };

    const replacePlainUrlsWithLinks = (htmlContent: string) => {
        return Autolinker.link(htmlContent, {
            newWindow: true,
            stripPrefix: false,
            phone: false,
            email: false,
            urls: {
                tldMatches: false,
            },
        });
    };

    return (
        <div className="app-messages flex-container">
            {isShowNewMessageVisible ? (
                <div className="center-position">
                    <CSSTransition
                        classNames="TabsAnimation"
                        in={true}
                        appear={true}
                        enter={true}
                        exit={true}
                        timeout={{ exit: 300, enter: 500, appear: 1000 }}>
                        <div
                            className="scroll-bottom"
                            onClick={() => showNewMessages()}>
                            <div className="scroll-bottom-text">
                                Show new messages
                            </div>
                            <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                    </CSSTransition>
                </div>
            ) : (
                []
            )}
            <div
                ref={autoScroller}
                className="overflow-container flex-container fit-content"
                style={{ paddingTop: 15 }}
                onScrollCapture={(e) => {
                    loadMessagePage(e);
                    if (
                        shouldScroll() &&
                        props.isNewMessageReceived &&
                        props.hasFirstPage
                    ) {
                        props.setIsNewMessageReceived(false);
                    }
                }}>
                {props.messages ? (
                    props.messages.map((message: Message) => {
                        if (props.numberOfLikes !== null && props.messages) {
                            switch (props.comparationType) {
                                case ComparationType.Equal:
                                    if (
                                        message.likerIds.length !==
                                        props.numberOfLikes
                                    ) {
                                        return <></>;
                                    }
                                    break;
                                case ComparationType.More:
                                    if (
                                        message.likerIds.length <=
                                        props.numberOfLikes
                                    ) {
                                        return <></>;
                                    }
                                    break;
                                case ComparationType.Less:
                                    if (
                                        message.likerIds.length >=
                                        props.numberOfLikes
                                    ) {
                                        return <></>;
                                    }
                                    break;
                            }
                        }
                        const messageSender = props.members.find(
                            (member) => member.id === message.senderId
                        );
                        const isSenderAuthorizedMember: boolean =
                            message.senderId == props.authorizedMemberId;

                        return (
                            <div
                                ref={
                                    message.id ===
                                    props.messageReference?.messageId
                                        ? referenceMessageRef
                                        : message.id === firstElementId
                                        ? firstElementRef
                                        : message.id === lastElementId
                                        ? lastElementRef
                                        : null
                                }
                                key={message.id}
                                className={
                                    (isSenderAuthorizedMember
                                        ? 'message-box sender-message'
                                        : 'message-box') +
                                    (highlightedMessageId === message.id ||
                                    props.editingMessageId === message.id
                                        ? ' hightlighted-message'
                                        : '')
                                }>
                                <div>
                                    <div
                                        className="avatar-icon-wrapper mr-1"
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                            props.showProfile(messageSender.id);
                                            e.stopPropagation();
                                        }}>
                                        {messageSender.onlineState ==
                                        OnlineState.Online ? (
                                            <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                                        ) : (
                                            <div className="badge badge-bottom btn-shine badge-secondary badge-dot badge-dot-lg" />
                                        )}
                                        <div className="avatar-icon avatar-icon-lg rounded">
                                            {messageSender.profile
                                                ?.avatarPath ? (
                                                <img
                                                    src={
                                                        messageSender.profile
                                                            .avatarPath
                                                    }
                                                    className="member-icon"
                                                />
                                            ) : (
                                                <div
                                                    className="member-icon"
                                                    style={{
                                                        background:
                                                            messageSender.backgroundColorCode,
                                                    }}>
                                                    <div
                                                        className="member-initials"
                                                        style={{
                                                            color: messageSender.foregroundColorCode,
                                                        }}>
                                                        {messageSender.initials}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {isSenderAuthorizedMember ? (
                                        <div className="person-name-placeholder"></div>
                                    ) : (
                                        <div className="chat-person-name">
                                            {messageSender.fullName}
                                        </div>
                                    )}
                                    <div className="message-block">
                                        <div
                                            className={
                                                'flex-row-container ' +
                                                (isSenderAuthorizedMember
                                                    ? 'chat-box-right'
                                                    : 'chat-box-left') +
                                                (props.isAdmin ? ' mr-2' : '')
                                            }>
                                            <div className={'chat-box'}>
                                                <div
                                                    className={
                                                        props.supportContext !=
                                                        null
                                                            ? 'like-message-block support-context-block'
                                                            : 'like-message-block'
                                                    }
                                                    style={{
                                                        paddingRight:
                                                            calculateTextWidth(
                                                                props.members.find(
                                                                    (member) =>
                                                                        member.id ===
                                                                        message.senderId
                                                                )?.fullName,
                                                                'SegoeUI-Semibold 14px'
                                                            ),
                                                    }}>
                                                    {props.supportContext !=
                                                    null ? (
                                                        <div className="support-context-label">
                                                            Context:{' '}
                                                            {
                                                                SupportContextItemList.find(
                                                                    (x) =>
                                                                        x.id ==
                                                                        props.supportContext
                                                                ).name
                                                            }
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {message.htmlContent ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: replacePlainUrlsWithLinks(
                                                                    message.htmlContent.replace(
                                                                        /(<? *script)/gi,
                                                                        ''
                                                                    )
                                                                ),
                                                            }}
                                                        />
                                                    ) : (
                                                        []
                                                    )}
                                                    <Badge
                                                        className={
                                                            isSenderAuthorizedMember
                                                                ? 'like-block left-offset'
                                                                : 'like-block right-offset'
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                !props.isReadonlyMode
                                                            ) {
                                                                props.setShouldScrollBottom(
                                                                    false
                                                                );
                                                                props.likeMessage(
                                                                    message.id
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            borderColor:
                                                                message.likerIds.includes(
                                                                    props.authorizedMemberId
                                                                )
                                                                    ? '#535bd6'
                                                                    : '#888f96',
                                                            color: message.likerIds.includes(
                                                                props.authorizedMemberId
                                                            )
                                                                ? '#535bd6'
                                                                : '#888f96',
                                                        }}>
                                                        {message.likerIds
                                                            .length > 0 ? (
                                                            <div className="number-of-likes">
                                                                {
                                                                    message
                                                                        .likerIds
                                                                        .length
                                                                }
                                                            </div>
                                                        ) : (
                                                            []
                                                        )}

                                                        <FontAwesomeIcon
                                                            className={
                                                                props.isAdmin ||
                                                                props.isReadonlyMode
                                                                    ? 'disabled'
                                                                    : ''
                                                            }
                                                            style={{
                                                                fontSize:
                                                                    message.likerIds.includes(
                                                                        props.authorizedMemberId
                                                                    )
                                                                        ? '110%'
                                                                        : '90%',
                                                            }}
                                                            icon={faThumbsUp}
                                                        />
                                                    </Badge>
                                                </div>
                                                <div className="attachments-wrapper">
                                                    {message.attachments
                                                        .length > 0 ? (
                                                        <div className="attachments">
                                                            {message.attachments?.map(
                                                                (attachment) =>
                                                                    getAttachment(
                                                                        attachment,
                                                                        message.id,
                                                                        message
                                                                            .attachments
                                                                            .length ===
                                                                            1 &&
                                                                            !message.htmlContent &&
                                                                            message
                                                                                .sharedContents
                                                                                .length ===
                                                                                0,
                                                                        isSenderAuthorizedMember
                                                                    )
                                                            )}
                                                        </div>
                                                    ) : (
                                                        []
                                                    )}
                                                    {message.sharedContents
                                                        .length > 0 ? (
                                                        <div className="attachments">
                                                            {message.sharedContents?.map(
                                                                (content) => (
                                                                    <div
                                                                        key={
                                                                            content.id
                                                                        }>
                                                                        <KnowledgeBaseContentTile
                                                                            isSelectable={
                                                                                false
                                                                            }
                                                                            showNotificationFlag={
                                                                                false
                                                                            }
                                                                            updateTables={() => {}}
                                                                            onContentUpdate={(
                                                                                isAddedToShelf: boolean
                                                                            ) =>
                                                                                props.updateContent(
                                                                                    props.selectedDiscussionId,
                                                                                    message.id,
                                                                                    content.id,
                                                                                    isAddedToShelf
                                                                                )
                                                                            }
                                                                            data={
                                                                                content
                                                                            }
                                                                            isDisableKbContent={
                                                                                props.isDisableKbContent
                                                                            }
                                                                            isSaveToShelfVisible={
                                                                                true
                                                                            }></KnowledgeBaseContentTile>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : (
                                                        []
                                                    )}

                                                    {message.giftCardTransactionId ? (
                                                        <div className="gift-card-wrapper">
                                                            <GiftCard
                                                                giftCardTransactionId={
                                                                    message.giftCardTransactionId
                                                                }></GiftCard>
                                                        </div>
                                                    ) : (
                                                        []
                                                    )}
                                                </div>
                                            </div>

                                            {props.isAdmin ? (
                                                <div className="edit-button">
                                                    {' '}
                                                    <img
                                                        onClick={() =>
                                                            props.setMessageToEdit?.(
                                                                message
                                                            )
                                                        }
                                                        src={editAction}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                []
                                            )}
                                        </div>
                                        <small
                                            className={
                                                isSenderAuthorizedMember
                                                    ? 'under-message sender-message'
                                                    : 'under-message'
                                            }>
                                            {Helpers.mapDate(
                                                message.dateCreated
                                            )}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Messages;
